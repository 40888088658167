import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { Button } from '@components/index';
import {
  Block,
  MaxWidthContainer,
  useSummaryPageTranslation
} from '@features/onboarding-2';

import { FormDataDisplay } from '../';
import { FormData } from '../../types';
import { MobileNumberInput, SummaryEmailInput } from './components';
import styles from './FormSection.module.css';

interface FormSectionProps {
  formData?: FormData;
  onChangeDetailsClick: () => void;
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
}

const FormSection = ({
  formData,
  onChangeDetailsClick,
  register,
  errors
}: FormSectionProps) => {
  const t = useSummaryPageTranslation();

  return (
    <Block className={styles.block}>
      <MaxWidthContainer>
        <div className={styles.blockContent}>
          <h4>{t('HEADING')}</h4>
          <FormDataDisplay formData={formData} />
          <Button
            variant="tertiary"
            hugged
            className={styles.changeDetailsButton}
            onClick={() => onChangeDetailsClick()}
          >
            {t('CHANGE_DETAILS_BUTTON')}
          </Button>
          <div className={styles.inputs}>
            <SummaryEmailInput
              register={register}
              errorMessage={
                formData?.emailId === ''
                  ? t('NO_EMAIL')
                  : errors.emailId?.message
              }
            />
            <MobileNumberInput
              register={register}
              errorMessage={errors.mobileNumber?.message}
            />
          </div>
        </div>
      </MaxWidthContainer>
    </Block>
  );
};

export default FormSection;

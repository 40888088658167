import { useForm, UseFormReturn } from 'react-hook-form';

import { FormData } from '../types';

const useFormSetup = (initialFormData: FormData): UseFormReturn<FormData> => {
  const methods = useForm<FormData>({
    values: initialFormData,
    mode: 'all',
    reValidateMode: 'onChange'
  });

  return methods;
};

export default useFormSetup;

import { UseFormRegister } from 'react-hook-form';

import {
  OnboardingTextFieldSimple,
  useSummaryPageTranslation
} from '@features/onboarding-2';
import { isPhoneNumber } from '@lib/utils';

import { FormData } from '../../../../types';
type Props = {
  register: UseFormRegister<FormData>;
  errorMessage?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const MobileNumberInput = ({ register, errorMessage }: Props) => {
  const t = useSummaryPageTranslation();

  return (
    <OnboardingTextFieldSimple
      {...register('mobileNumber', {
        pattern: {
          value: isPhoneNumber,
          message: t('ERRORS.INVALID_PHONE_NUMBER')
        }
      })}
      id="phone"
      label={t('LABELS.PHONE_NUMBER')}
      type="tel"
      error={errorMessage}
    />
  );
};

export default MobileNumberInput;

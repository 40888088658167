import { UseFormRegister } from 'react-hook-form';

import {
  OnboardingTextFieldSimple,
  useSummaryPageTranslation
} from '@features/onboarding-2';
import { isEmail } from '@lib/utils';

import { FormData } from '../../../../types';
type Props = {
  register: UseFormRegister<FormData>;
  errorMessage?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SummaryEmailInput = ({ register, errorMessage }: Props) => {
  const t = useSummaryPageTranslation();

  return (
    <OnboardingTextFieldSimple
      {...register('emailId', {
        pattern: {
          value: isEmail,
          message: t('ERRORS.INVALID_EMAIL')
        },
        required: {
          value: true,
          message: t('NO_EMAIL')
        }
      })}
      id="email"
      label={t('LABELS.EMAIL')}
      type="email"
      error={errorMessage}
      required
    />
  );
};

export default SummaryEmailInput;
